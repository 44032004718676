<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    :query="`${query}&filter[is_allowance]=0`"
    entity-name="佛事"
    add-btn=""
    hide-show-detail
    hide-edit
    :action="action"
    :rules="rules"
    :columns="columns"
    :request-config="{noTempleFilter: true}"
    :deal-list="dealList"
    :custom-delete="customDelete">
    <template v-slot:title>
      <span>不参与补贴佛事列表</span>
    </template>
    <template v-slot:button>
      <entity-picker
        btn-text="选择佛事"
        no-temple-filter
        :url="url"
        :query="`${query}&filter[is_allowance]=1&sort=-update_time`"
        :deal-list="dealList"
        :columns="selectColumns"
        @change="handleChange"
        style="display: inline-block;margin-left: 16px;">
        <template v-slot:query="{form}">
          <a-form-model-item label="佛事日期" prop="date">
            <a-date-picker
              v-model="form.date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              placeholder="选择日期筛选"
              style="width: 180px;"
            />
          </a-form-model-item>
        </template>
        <a-button type="primary">添加</a-button>
      </entity-picker>
    </template>
  </data-list>
</template>

<script>
    import {getBuddhaTypeName} from "../../common/buddha/type";
    import {getBuddhismStatusText, getBuddhismWayText} from "../../common/buddha/buddhism";
    import EntityPicker from "../../components/controls/entity-picker";
    import {getTemple} from "../../common/js/storage";

    export default {
        name: "FreeBuddhism",
        components: {EntityPicker},
        data() {
            return {
                url: "/admin/fs",
                columns: [
                    {title: '佛事时间', dataIndex: 'buddhaTime', width: 200},
                    {title: '类型', dataIndex: 'typeText'},
                    {title: '操作人', dataIndex: 'allowance.wx_user_id', customRender: text => text ? <open-data type="userName" openid={text} /> : <span>--</span>},
                    {title: '操作时间', dataIndex: 'allowance.create_time'},
                ],
                selectColumns: [
                    {title: '佛事时间', dataIndex: 'buddhaTime', width: 200},
                    {title: '类型', dataIndex: 'typeText'},
                ],
                rules: {
                    date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
                    buddhism: [{ required: true, message: '请选择佛事', trigger: 'blur' }],
                }
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
            query() {
                return `&expand=temp,timeRange,allowance&temple_id=${getTemple()}`
            }
        },
        methods: {
            handleChange(val) {
                this.updateAllowance(val.id, 0).then(() => {
                    this.updateList();
                });
            },
            customDelete(item, index, fn) {
                this.$confirm({
                    title: '提示',
                    content: '确定删除此记录吗？',
                    onOk: () => {
                        this.updateAllowance(item.id, 1).then(fn);
                    }
                });
            },
            updateAllowance(id, value) {
                return this.$axios({
                    url: `/admin/fs/${id}`,
                    method: 'PATCH',
                    data: {
                        is_allowance: value
                    }
                });
            },
            dealList(list, fn) {
                list.forEach(item => {
                    item.typeText = getBuddhaTypeName(item.temp.fs_type);
                    item.statusText = getBuddhismStatusText(item);
                    item.wayText = getBuddhismWayText(item);
                    if(item.timeRange) {
                        item.buddhaTime = `${item.date} ${item.timeRange ? item.timeRange.name : ''}`
                    } else {
                        item.buddhaTime = '--'
                    }
                    // 后台登记的时候就选择了不参与补贴 操作人为登记人
                    if (!item.allowance && item.is_allowance == 1 && item.admin_id && isNaN(item.admin_id * 1)) {
                        item.allowance = {
                            fs_id: item.id,
                            wx_user_id: item.admin_id,
                            create_time: item.create_time
                        }
                    }
                });
                fn();
            },
            updateList() {
                this.$refs.dataList.getList();
            }
        }
    }
</script>

<style scoped>

</style>
